import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import { SpecialOfferProps } from './SpecialOIffer.types';
import {
  BagelCodeSpecialOffer,
  LocoBingoSpecialOffer,
  Me2onSpecialOffer,
  PipaSpecialOffer,
  MatchMastersSpecialOffer,
  PokerFaceSpecialOffer,
  HugeSpecialOffer,
  ProductMadnessSpecialOffer,
  PlaySimpleSpecialOffer,
} from '@appcharge/shared-ui';
import {
  EBundlesInternalViewModel,
  ESalePercentageDisplayType,
  ESpecialOfferInternalViewModel,
} from '../../constants/enums';

const bundleDesignDic = {
  [ESpecialOfferInternalViewModel.HUGE]: HugeSpecialOffer,
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: MatchMastersSpecialOffer,
  [ESpecialOfferInternalViewModel.POKER_FACE]: PokerFaceSpecialOffer,
  [ESpecialOfferInternalViewModel.BAGELCODE]: BagelCodeSpecialOffer,
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: LocoBingoSpecialOffer,
  [ESpecialOfferInternalViewModel.ME2ON]: Me2onSpecialOffer,
  [ESpecialOfferInternalViewModel.PIPA]: PipaSpecialOffer,
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.PLAY_SIMPLE]: PlaySimpleSpecialOffer,
};
const TIME_LEFT_INTERVAL = 1000;

const SpecialOffer = ({ data, selectOffer }: SpecialOfferProps) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const borderColor = publisherMetaData.storeTheme.storeScreen.bundleBorderColor;
  const SpecialOfferDesign =
    bundleDesignDic[publisherMetaData?.storeTheme.general.specialOffersInternalViewModel];
  const specialOfferRef = useRef<HTMLDivElement>(null);
  const [timer, setTimer] = useState('');
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const { t } = useTranslation();
  // Format the timeLeft into HH:MM:SS format
  useEffect(() => {
    if (timeLeft === null) {
      setTimer('');
    } else if (timeLeft < TIME_LEFT_INTERVAL) {
      setTimer('00:00:00');
    } else {
      const totalSeconds = Math.floor(timeLeft / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      setTimer(
        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }
  }, [timeLeft]);

  // Effect to trigger the timer calculation when data or schedule changes
  useEffect(() => {
    if (data?.schedule && !data.schedule.permanent) {
      const endTime = data?.schedule?.timeFrames[0].endTime;
      if (endTime) {
        calculateTimeLeft(endTime);
      }
    }
  }, [data]);

  // Calculate time left until the endTime
  const calculateTimeLeft = (endTime: string | Date) => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const endTimeDate = new Date(endTime).getTime();

      const remainingTime = endTimeDate - currentTime;

      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
      } else {
        setTimer('00:00:00');
        clearInterval(interval);
        API.getOffersV2.refetch();
      }
    }, TIME_LEFT_INTERVAL);

    return () => clearInterval(interval);
  };

  return (
    <SpecialOfferDesign
      availability={data.playerAvailability}
      playerAvailability={data.offerPlayerRemainingAvailability}
      text={data.offerUi.specialOffer!.title}
      backgroundColor={data.offerUi.specialOffer!.backgroundColor}
      textColor={data.offerUi.specialOffer!.fontColor}
      fontSize={data.offerUi.specialOffer!.fontSize}
      fontWeight={data.offerUi.specialOffer!.fontWeight}
      borderColor={borderColor}
      buttonColor={publisherMetaData.storeTheme.general.buttonColor}
      backgroundImage={data.offerUi.backgroundImage}
      design={
        publisherMetaData.storeTheme.general.bundlesInternalViewModel ===
        EBundlesInternalViewModel.MATCH
          ? EBundlesInternalViewModel.MATCH
          : publisherMetaData.storeTheme.general.specialOffersInternalViewModel
      }
      price={data.productsSequence[0].price}
      productsList={data.productsSequence[0].products}
      selectOffer={() => selectOffer()}
      buttonTextColor="white"
      salePercentage={data.dynamicOfferUi?.salePercentage}
      salePercentageDisplayType={
        data.dynamicOfferUi?.salePercentageDisplayType ||
        ('percentage' as ESalePercentageDisplayType)
      }
      isFree={data.productsSequence[0].price.price === 0}
      availabilityText={t('availability')}
      collectText={t('collect')}
      specialOfferRef={specialOfferRef}
      timer={timer}
      translations={{ includes: t('bundles.subProductsPrefixText') }}
    />
  );
};

export default SpecialOffer;
