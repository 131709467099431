import { HeartOfVegasPlayerLevel } from '@appcharge/shared-ui';
import React from 'react';
import './style.scss';
import { EBundlesInternalViewModel } from '../../constants/enums';

export interface IPlayerLevelWrapperProps {
  viewModel: EBundlesInternalViewModel;
  assetUrl: string;
  text: string;
  endsIn: number;
  refetchHandler: () => void;
}

const mapPlayerLevelToTemplate: Partial<Record<EBundlesInternalViewModel, any>> = {
  [EBundlesInternalViewModel.HEART_OF_VEGAS]: HeartOfVegasPlayerLevel,
};

export const PlayerLevelWrapper: React.FC<IPlayerLevelWrapperProps> = ({
  viewModel,
  assetUrl,
  text,
  endsIn,
  refetchHandler,
}) => {
  const PlayerLevel = mapPlayerLevelToTemplate[viewModel];

  if (!PlayerLevel) return <></>;

  return (
    <PlayerLevel
      imageSrc={assetUrl}
      text={text}
      endsIn={endsIn}
      onTimerDoneHandler={refetchHandler}
    />
  );
};
