import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { AddToHomePageModal, AddToHomeScreenButton } from '@appcharge/shared-ui';
import useSaveToHomePage from '../../hooks/useSaveToHomePage';
import useStoreAppearance from '../../hooks/useStoreAppearance';
import { EEventsType, EPlatformType, EStorePhase } from '../../constants/enums';

const AddToHomePage = ({ publisherMetaData, customEvents }: any) => {
  const [devicePlatform, setDevicePlatform] = useState(EPlatformType.WEB);
  const {
    useSaveToHomePageNotification,
    isSaveToHomePageOpen,
    setIsSaveToHomePageOpen,
    handleCloseSaveToHomePage,
    isDisplayAddToHomePageButton,
    handleInstallApp,
    isAndroidFallBack,
  } = useSaveToHomePage(publisherMetaData);
  const isDarkMode = useStoreAppearance();

  useEffect(() => {
    if (isIOS) {
      setDevicePlatform(EPlatformType.IOS);
    }
    if (isAndroid) {
      setDevicePlatform(EPlatformType.ANDROID);
    }
  }, [isAndroid, isIOS]);

  const isDisplayAddToHomeScreenButton =
    useSaveToHomePageNotification && (isIOS || isAndroid) && isDisplayAddToHomePageButton;

  if (!isDisplayAddToHomeScreenButton) {
    return null;
  }

  const isIOSBanner = isIOS && isSaveToHomePageOpen;
  const isAndroidBanner = isAndroid && isAndroidFallBack;

  const handleAddToHomeScreen = () => {
    if (isIOS) {
      setIsSaveToHomePageOpen(true);
    }
    if (isAndroid) {
      return handleInstallApp();
    }

    customEvents.sendCustomEvent(
      EEventsType.HOME_SCREEN_BUTTON_CLICK,
      {
        platform: devicePlatform,
      },
      EStorePhase.POST_LOGIN
    );
  };

  return (
    <>
      <AddToHomeScreenButton
        onClick={handleAddToHomeScreen}
        borderColor={publisherMetaData.storeTheme.storeScreen.addToHomeScreen?.borderColor.colorOne}
        backgroundColor={publisherMetaData.storeTheme.storeScreen.addToHomeScreen?.backgroundColor}
      />
      {(isIOSBanner || isAndroidBanner) && (
        <AddToHomePageModal
          publisherStoreLogo={publisherMetaData.storeTheme.general.logo}
          publisherStoreName={publisherMetaData.publisher?.storeTabName}
          isDarkMode={isDarkMode}
          onClick={handleCloseSaveToHomePage}
          isAndroid={isAndroidBanner}
        />
      )}
    </>
  );
};

export default AddToHomePage;
