import { PaypalButtonProps } from './PaypalButton.types';
import { EEventsType, ELocalStorageKeys, EStorePhase } from '../../constants/enums';
import { useNavigate } from 'react-router-dom';
import { localStorageUtil } from '../../utils';
import useCustomEvents from '../../hooks/useCustomEvents';
import useApi from '../../hooks/useApi';
import { PayPalScriptProvider, BraintreePayPalButtons } from '@paypal/react-paypal-js';
import Decimal from 'decimal.js';

const PaypalButton = ({
  clientToken,
  selectedOffer,
  utmSource,
  startLoader,
}: PaypalButtonProps) => {
  const API = useApi({});
  const navigate = useNavigate();
  const customEvents = useCustomEvents();
  const currencyCode = API.getPostLoginData.data.playerCurrencyCode;
  const multiplier = API.getPostLoginData.data.multiplier;

  return (
    <PayPalScriptProvider
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test',
        'data-client-token': clientToken,
        'disable-funding':
          'card,venmo,credit,paylater,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,mercadopago',
        'enable-funding': 'paypal',
        currency: currencyCode,
      }}
    >
      <BraintreePayPalButtons
        style={{ height: 50 }}
        className="button"
        createOrder={async (data, actions) => {
          customEvents.sendCustomEvent(
            EEventsType.PAYMENT_CHOOSE,
            {
              payment_method: 'paypal',
              bundle_Id: selectedOffer.offerId,
              bundle_name: selectedOffer.offerName,
            },
            EStorePhase.POST_LOGIN
          );
          const paymentData = {
            data: {
              offerId: selectedOffer.offerId,
              clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
              paymentProvider: 'paypal',
              paymentMethod: 'paypal',
              utmSource: utmSource,
            },
          };
          const getOrderResponse = async () => {
            try {
              return await API.createOrder.mutateAsync(paymentData);
            } catch (error) {
              console.error(error);
              customEvents.sendCustomEvent(
                EEventsType.ERROR,
                {
                  type: 'api error',
                  route: 'createOrder',
                  provider: 'paypal',
                },
                EStorePhase.POST_LOGIN
              );
              navigate('../failed?msg=creating order error');
            }
          };
          const orderResponse = await getOrderResponse();
          if (!orderResponse) throw new Error('create order failed');
          localStorageUtil.set(ELocalStorageKeys.ORDER_ID, orderResponse.data.orderId);

          return actions.braintree.createPayment({
            flow: 'checkout',
            amount: new Decimal(orderResponse.data.price.priceTotal).div(multiplier).toString(),
            currency: currencyCode,
            requestBillingAgreement: false,
            intent: 'capture',
            enableShippingAddress: true,
            shippingAddressEditable: false,
            // billingAgreementDescription: 'Description of the billng agreement to display to the customer',
            lineItems: [
              {
                quantity: '1',
                unitAmount: new Decimal(orderResponse.data.price.priceNet)
                  .div(multiplier)
                  .toString(),
                name: selectedOffer.offerName,
                kind: 'debit',
                unitTaxAmount: new Decimal(orderResponse.data.price.tax).div(multiplier).toString(),
                url: undefined,
                description: '',
                productCode: selectedOffer.offerId,
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.braintree.tokenizePayment(data).then(async (payload) => {
            startLoader();
            const orderId = localStorageUtil.get(ELocalStorageKeys.ORDER_ID);
            const checkoutData = {
              data: {
                orderId: orderId,
                paymentProvider: 'paypal',
                params: {
                  paymentNonce: payload.nonce,
                },
              },
            };
            API.checkoutOrder.mutateAsync(checkoutData, {
              onSuccess: () => {
                navigate(`/checkout/${orderId}`);
              },
              onError: (error) => {
                console.error(error);
                customEvents.sendCustomEvent(
                  EEventsType.ERROR,
                  {
                    type: 'api error',
                    route: 'createOrder',
                  },
                  EStorePhase.POST_LOGIN
                );
              },
            });
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalButton;
