import { useNewRelic } from '../components/NewRelicProvider';
import { localStorageUtil } from '../utils';
import { ELocalStorageKeys } from '../constants/enums';

export default function useFEMonitoring() {
  const { newRelicBrowserAgent } = useNewRelic();
  const logToNewRelic = async (error: any) => {
    const playerData = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
    if (newRelicBrowserAgent?.log) {
      newRelicBrowserAgent.log(error?.message, {
        customAttributes: {
          requestURL: error?.config?.url || 'Unknown URL',
          playerCountry: playerData?.playerCountry,
          playerId: playerData?.playerId,
          playerName: playerData?.playerName,
          hasTrace: true,
          level: 'error',
        },
      });
    } else {
      console.error('Error logged:', error?.message || error.toString());
    }
  };

  return {
    logToNewRelic,
  };
}
