import { HeaderWrapper } from '../../components/HeaderWrapper/HeaderWrapper';
import { Bundle } from '../../components/Bundle/Bundle';
import useApi from '../../hooks/useApi';
import { BootResponse, OfferData } from '../../constants/apiResponses.types';
import PaypalButton from '../../components/PaypalButton/PaypalButton';
import LogoutModal from '../../components/LogoutModal/LogoutModal';
import { paymentMethodsDefault } from '../../constants/defaults';
import {
  AcCircularLoader,
  MessageBox,
  Overlay,
  PaymentModal,
  ProductMadnessValueBlock,
  useIsDesktop,
  useIsLandscape,
  Banner,
} from '@appcharge/shared-ui';
import SpecialOffer from '../../components/SpecialOffer/SpecialOffer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  EBundlesInternalViewModel,
  ECheckoutPageEvent,
  ECollectType,
  EEventsType,
  ELocalStorageKeys,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EQueryParams,
  EResultOptions,
  ESpecialOfferInternalViewModel,
  EStorePhase,
} from '../../constants/enums';
import {
  collectImagesFromObject,
  formatOfferDataToPublisherWebhook,
  getAppVersion,
  getBadgeName,
  getPlatformData,
  localStorageUtil,
} from '../../utils';
import useUrlQuery from '../../hooks/useUrlQuery';
import './style.scss';
import { OfferBalance, ShopPageProps } from './shop.types';
import { Stack } from '@mui/material';
import useCustomEvents from '../../hooks/useCustomEvents';
import Footer from '../../components/footer/footer';
import Carousel from '../../components/Carousel/Carousel';
import CarouselItem from '../../components/Carousel/CarouselItem';
import { isAndroid, isIOS } from 'react-device-detect';
import { PopUpsHandler } from '../../components/PopUpsHandler/PopUpsHandler';
import { useTranslation } from 'react-i18next';
import { BundlesList } from '../../components/BundlesList/BundlesList';
import { EOrderStatus } from '../checkout/checkout.types';
import Checkout from '../../components/Checkout/Checkout';
import AddToHomePage from '../../components/AddToHomePage/AddToHomePage';
import useSaveToHomePage from '../../hooks/useSaveToHomePage';

const jackpotSlotsValueBlock = 'https://media.appcharge.com/media/shop-images/JMS-banner.png';
const bigFishValueBlock = 'https://media.appcharge.com/media/shop-images/BFC-banner.png';
const jackpotSlotsDesktopLogo =
  'https://media.appcharge.com/media/shop-images/PJS--welcome-logo-desktop.png';
const bigFishDesktopLogo =
  'https://media.appcharge.com/media/shop-images/BFC--welcome-logo-desktop.png';

enum EConfigs {
  bannerMaxWidth = 'bannerMaxWidth',
  bannerMinWidth = 'bannerMinWidth',
}

const mapBundlesConfigs: Record<EConfigs, Partial<Record<EBundlesInternalViewModel, any>>> = {
  [EConfigs.bannerMaxWidth]: {
    [EBundlesInternalViewModel.WHISPERS]: '418px',
    [EBundlesInternalViewModel.PIPA]: '528px',
  },
  [EConfigs.bannerMinWidth]: {
    [EBundlesInternalViewModel.WHISPERS]: '343px',
  },
};

const defaultConfigs: Record<EConfigs, any> = {
  [EConfigs.bannerMaxWidth]: undefined,
  [EConfigs.bannerMinWidth]: undefined,
};

export const getProperty: any = (property: EConfigs, viewModel: EBundlesInternalViewModel) =>
  mapBundlesConfigs[property][viewModel] || defaultConfigs[property];

const ShopPage = ({ logout }: ShopPageProps) => {
  const [orderProcessing, setOrderProcessing] = useState<string>();
  const API = useApi({ orderId: orderProcessing });
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
  const isLoginRedirect = useUrlQuery(EQueryParams.LOGIN_REDIRECT) === 'true';
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const [offersLoadedReported, setOffersLoadedReported] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<OfferData>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isProductMadness, setIsProductMadness] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [specialOffersList, setSpecialOffersList] = useState<any>([]);
  const [bundlesList, setBundlesList] = useState<any[] | null>(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const fetchInterval = useRef(0);
  const CAROUSEL_LIMIT = 8;
  const OFFERS_INTERVAL_TIME = 30000;
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const { bundlesInternalViewModel, specialOffersInternalViewModel } =
    publisherMetaData.storeTheme.general;
  const [orderState, setOrderState] = useState<string>();
  const [showFailCollect, setShowFailCollect] = useState(false);
  const [useNewCheckout, setUseNewCheckout] = useState<boolean>(false);
  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { isDisplayAddToHomePageButton } = useSaveToHomePage(publisherMetaData);
  const productMadnessValueBlock =
    bundlesInternalViewModel === 'jackpot-slots' ? jackpotSlotsValueBlock : bigFishValueBlock;
  const productMadnessDesktopLogo =
    bundlesInternalViewModel === 'jackpot-slots' ? jackpotSlotsDesktopLogo : bigFishDesktopLogo;
  const [initialBundlesLoadingTime, setInitialBundlesLoadingTime] = useState<number>();
  const [arrOffersImages, setArrOffersImages] = useState<string[]>();
  const [resultIframeSrc, setResultIframeSrc] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState(true);

  // refetch order status when orderProcessing changes
  useEffect(() => {
    if (orderProcessing) {
      API.getOrder.refetch().catch((err) => {
        console.error(err);
        showFailedScreen();
      });
    }
  }, [orderProcessing]);

  // check if order status changed
  useEffect(() => {
    if (orderProcessing && API.getOrder.data) {
      setOrderState(API.getOrder.data?.state);
    }
  }, [API?.getOrder?.data]);

  useEffect(() => {
    if (orderProcessing === undefined) return;
    if (
      orderState === EOrderStatus.CHARGE_SUCCEED ||
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: selectedOffer?.offerId,
          result:
            orderState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          offer_name: selectedOffer?.offerName,
          platform: getPlatformData(),
          type: selectedOffer?.offerType,
          sub_type: selectedOffer?.subType,
          reason: orderState !== EOrderStatus.CHARGE_SUCCEED ? orderState : '',
        },
        EStorePhase.POST_LOGIN
      );
    }
    if (orderState === EOrderStatus.CHARGE_SUCCEED) {
      setProcessing(false);
      if (useNewCheckout) {
        setShowPaymentModal(true);
      } else {
        navigate(`/checkout/${orderProcessing}`);
      }
    } else if (
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      setProcessing(false);
      showFailedScreen();
      setSelectedOffer(undefined);
    } else {
      const timer = setTimeout(() => {
        API.getOrder.refetch().catch((err) => console.error(err));
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [orderState, API.getOrder.isRefetching]);

  useEffect(() => {
    const timer = setTimeout(() => {
      finishBundlesLoad();
    }, 10000);

    const now = Date.now();
    setInitialBundlesLoadingTime(now);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const keepAlive = async () => {
    API.keepAlive.mutate(
      { data: {}, headers: {} },
      {
        onError: () => {
          customEvents.sendCustomEvent(
            EEventsType.LOGOUT,
            {
              reason: 'session ended',
            },
            EStorePhase.PRE_LOGIN
          );
          customEvents.resetSession();
          logout();
        },
        onSuccess: () => {
          customEvents.setDistinctId();
          setUseNewCheckout(!!publisherMetaData.featureFlags?.store_independent_checkout_flow);
          API.getOffers.refetch();
          fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
          if (!isLoggedIn) setIsLoggedIn(true);
        },
      }
    );
  };

  if (API.keepAlive.isIdle) {
    if (!localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
      window.location.href = `/login${utmSource ? `?utm_source=${utmSource}` : ''}`;
    } else {
      keepAlive();
    }
  }

  const fetchIntervalHandler = () => {
    if (!document.hasFocus()) return;
    API.getOffers.refetch();
    fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
  };

  useEffect(() => {
    if (!publisherMetaData.featureFlags?.store_refresh_offers_stale_mode) return;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        API.getOffers.refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [publisherMetaData.featureFlags]);

  const onCollect = async (item: OfferData): Promise<void> => {
    setProcessing(true);
    const freeOrderData: any = {
      data: {
        clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
        paymentProvider: 'free',
        paymentMethod: 'free',
        utmSource: null,
        offerId: item.offerId,
      },
    };
    customEvents.sendCustomEvent(
      EEventsType.COLLECT_CLICKED,
      {
        offer_id: selectedOffer?.offerId,
        offer_name: selectedOffer?.offerName,
        platform: getPlatformData(),
        type: ECollectType.OFFER,
      },
      EStorePhase.POST_LOGIN
    );

    API.createOrder.mutate(freeOrderData, {
      onSuccess: (orderData) => {
        setOrderProcessing(orderData.data.orderId);
        API.getOffers.refetch();
      },
      onError: (error) => {
        console.error(error);
        setProcessing(false);
        navigate('../failed?msg=creating order error');
      },
    });
  };

  const selectOffer = (offerData: OfferData) => {
    const priceData = offerData?.productsSequence[0].price;
    if (priceData.price === 0) {
      localStorageUtil.set(ELocalStorageKeys.IS_FREE_ORDER_SELECTED, true);
    } else {
      if (localStorageUtil.get(ELocalStorageKeys.IS_FREE_ORDER_SELECTED)) {
        localStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
      }
    }
    setSelectedOffer(offerData);
    if (priceData.price === 0) {
      onCollect(offerData);
    } else {
      setShowPaymentModal(true);
      const products = offerData.productsSequence[0].products.map((product) => {
        const { name, quantity } = product;
        return {
          name,
          quantity,
        };
      });
      customEvents.sendCustomEvent(
        EEventsType.OFFER_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          badge_left: getBadgeName('left', offerData.dynamicOfferUi?.badges),
          badge_right: getBadgeName('right', offerData.dynamicOfferUi?.badges),
          badge_center: getBadgeName('center', offerData.dynamicOfferUi?.badges),
          sale_percentage: offerData.dynamicOfferUi?.salePercentage,
          type: offerData.offerType,
          sub_type: offerData?.subType,
          products,
          bundle_price_usd: priceData.price,
          player_local_currency: priceData.currencyCode,
          bundle_price_total: priceData.price,
          offer_id_external: offerData.publisherOfferId,
          offer_internal_id: offerData.offerId,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };

  const closePopup = () => {
    setShowLogoutModal(false);
    setShowPaymentModal(false);
    setOrderProcessing('');
  };

  const paymentClicked = async (
    method: string,
    provider = 'stripe',
    offerSelected = selectedOffer
  ) => {
    setProcessing(true);
    closePopup();
    if (!offerSelected) return;
    const currency = offerSelected.productsSequence[0].price.currencyCode;
    const paymentData: any = {
      data: {
        clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
        currency: currency.toLowerCase(),
        paymentProvider: provider,
        paymentMethod: method,
        utmSource,
        offerId: offerSelected.offerId,
      },
    };

    customEvents.sendCustomEvent(
      EEventsType.PAYMENT_CHOOSE,
      {
        payment_method: method,
        bundle_Id: offerSelected.offerId,
        bundle_name: offerSelected.offerName,
      },
      EStorePhase.POST_LOGIN
    );
    API.createOrder.mutate(paymentData, {
      onSuccess: (response) => {
        setProcessing(false);
        window.location.href = response.data.paymentUrl;
      },
      onError: (data: any) => {
        setProcessing(false);
        console.error(data);
        customEvents.sendCustomEvent(
          EEventsType.ERROR,
          {
            type: 'api error',
            route: 'createOrder',
          },
          EStorePhase.POST_LOGIN
        );
        navigate('../failed?msg=creating order error');
      },
    });
  };

  const openLogoutModal = () => {
    customEvents.sendCustomEvent(EEventsType.PROFILE_MENU_CLICKED, {}, EStorePhase.POST_LOGIN);
    setShowLogoutModal(true);
  };

  const finishBundlesLoad = () => {
    setAllImagesLoaded(true);

    if (initialBundlesLoadingTime) {
      const now = Date.now();

      const loadingTimeInMs = now - initialBundlesLoadingTime;

      customEvents.sendCustomEvent(
        EEventsType.OFFERS_IMAGES_LOADED,
        {
          offers: API.getOffersV2.data.offers?.length,
          bundles: API.getOffersV2.data.offers.bundles?.length,
          loadingTime: loadingTimeInMs,
          images: arrOffersImages,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };

  useEffect(() => {
    if (!API.getOffers.data) return;
    const specialOffers = API.getOffers.data.offers.filter(
      (offer: OfferData) => offer.offerType === 'SpecialOffer'
    );
    const bundles = API.getOffers.data.offers.filter(
      (offer: OfferData) => offer.offerType === 'Bundle'
    );
    setSpecialOffersList(specialOffers);
    setBundlesList(
      bundles.map((offer: OfferData, i: number) => (
        <Bundle index={i} key={offer.offerId} data={offer} selectOffer={() => selectOffer(offer)} />
      ))
    );
    if (!offersLoadedReported) {
      customEvents.sendCustomEvent(
        EEventsType.OFFERS_LOADED,
        {
          specialOffers: specialOffers.length,
          bundles: bundles.length,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFERS_LOADED,
          offers: formatOfferDataToPublisherWebhook(API.getOffers.data.offers),
          appVersion: getAppVersion(),
        },
      });
      setOffersLoadedReported(true);
    }
    setIsProductMadness(
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        bundlesInternalViewModel
      )
    );
  }, [API.getOffers.data]);

  useEffect(() => {
    if (!publisherMetaData.supportConfiguration?.postLoginSnippet) {
      setIsScriptLoaded(true);
    }
    const backgroundImage =
      isDesktop || isLandscape
        ? publisherMetaData.storeTheme.general.backgroundImageDesktop
        : publisherMetaData.storeTheme.general.backgroundImageMobile;
    document.body.setAttribute(
      'style',
      `background-image: url(${backgroundImage}); background-size: cover; background-attachment: fixed; background-position: top center`
    );

    const checkIframeVisibility = () => {
      const iframe = document.getElementById('hs-web-sdk-iframe');
      if (iframe && window.getComputedStyle(iframe).visibility === 'visible') {
        setIsScriptLoaded(true);
        return true;
      }
      return false;
    };

    const script = document.createElement('script');
    script.src = publisherMetaData.supportConfiguration?.postLoginSnippet;
    script.onload = () => {
      if (!checkIframeVisibility()) {
        const intervalId = setInterval(() => {
          if (checkIframeVisibility()) {
            clearInterval(intervalId);
          }
        }, 100);
      }
    };
    document.body.appendChild(script);
    const onFocus = () => {
      customEvents.sendCustomEvent(
        EEventsType.SHOP_LAND,
        {
          player_activity: 'test',
          platform: getPlatformData(),
          source: sessionStorage.getItem('source'),
          add_to_home_screen_appear: isDisplayAddToHomePageButton,
        },
        EStorePhase.POST_LOGIN
      );
      keepAlive();
    };
    const onBlur = () => {
      clearTimeout(fetchInterval.current);
    };
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
      document.body.removeChild(script);
    };
    // eslint-disable-next-line
  }, [isDesktop, isLandscape]);

  useEffect(() => {
    if (API.getOffers.isError) {
      customEvents.resetSession();
      logout();
    }
  }, [API.getOffers.isError]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const init = async () => {
      customEvents.sendCustomEvent(
        EEventsType.SHOP_LAND,
        {
          player_activity: isLoginRedirect ? 'post login' : 'normal',
          platform: getPlatformData(),
          source: sessionStorage.getItem('source'),
          add_to_home_screen_appear: isDisplayAddToHomePageButton,
        },
        EStorePhase.POST_LOGIN
      );
      await API.getPostLoginData.refetch();
      if (publisherMetaData?.storeTheme?.publisherId === '63ec904c793c0ad830e70b3e') {
        // ONLY FOR BABA
        API.getPaypalKey.refetch();
      }
    };
    init();
    return () => {
      clearTimeout(fetchInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (!API.getOffers?.data?.offers) return;
    let loadedImagesCount = 0;

    const allImagesSet: Set<string> = new Set();

    if (!API.getOffers.data.offers?.length) {
      finishBundlesLoad();
      return;
    }

    API.getOffers?.data.offers?.forEach((bundle: any) => {
      collectImagesFromObject(bundle, allImagesSet);
    });

    const allImagesArray = Array.from(allImagesSet);

    const isOnlyNoImage = allImagesArray.length === 1 && allImagesArray[0] === 'noImage';

    if (isOnlyNoImage) {
      finishBundlesLoad();
      return;
    }
    setAllImagesLoaded(false);
    setArrOffersImages(allImagesArray);

    allImagesArray.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImagesCount += 1;
        if (loadedImagesCount + 2 == allImagesArray.length || allImagesArray.length <= 2) {
          finishBundlesLoad();
        }
      };
      img.onerror = () => {
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_IMAGE_FAILED_LOADING,
          {
            offers: API.getOffersV2.data.offers?.length,
            bundles: API.getOffersV2.data.offers.bundles?.length,
            image: img.src,
          },
          EStorePhase.POST_LOGIN
        );
        console.error('Failed to load:', img.src);
      };
    });
  }, [API.getOffers?.data?.offers]);

  const isNoSpecialOffers =
    API.getOffers.data &&
    localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) &&
    bundlesList?.length === 0 &&
    specialOffersList?.length === 0;

  const getBalancesData = () => {
    const data: OfferBalance[] = API.getOffers?.data?.balances;
    if (!data) return;
    return data.map((item) => ({
      ...item,
      image: item.images.productPrefix || item.images.product,
      amount: item.quantity,
    }));
  };

  const isDividerDisplay = [
    EBundlesInternalViewModel.LOCO_BINGO,
    EBundlesInternalViewModel.BAGELCODE,
    EBundlesInternalViewModel.PIPA,
    EBundlesInternalViewModel.PLAY_SIMPLE,
  ].includes(bundlesInternalViewModel);

  const showFailedScreen = () => {
    setShowFailCollect(true);
    setResultIframeSrc(
      `${window.location.origin}/failed?${EQueryParams.IS_IFRAME}=true&error=storeFailure`
    );
  };

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { params, event } = massageEvent.data;
      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        setShowFailCollect(false);
        return;
      }
      if (event === ECheckoutPageEvent.SUPPORT) {
        navigate(params.supportUrl);
        return;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  // This is "hack" for Firefox issue with "onLoad" call
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'iframe-loaded') {
        setIframeLoading(false);
        event.source?.postMessage('stop-messages');
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const bannerImage = useMemo(() => {
    if (!API.getOffers?.data?.offers || API.getOffers.data.offers.length === 0) {
      return '';
    }

    const bannerKeyValue =
      API.getOffers.data.bannerImage || publisherMetaData.storeTheme.storeScreen.defaultBanner;

    if (bannerKeyValue) return bannerKeyValue;

    if (
      API.getOffers.data.playerLevelImg &&
      [
        EBundlesInternalViewModel.CASHMAN_CASINO,
        EBundlesInternalViewModel.PIPA,
        EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
      ].includes(bundlesInternalViewModel)
    ) {
      return API.getOffers.data.playerLevelImg;
    }

    return '';
  }, [API.getOffers.data]);

  return (
    <div className="storeWrapper">
      <AddToHomePage publisherMetaData={publisherMetaData} customEvents={customEvents} />
      {(isAndroid || isIOS) && (
        <div
          className={'shop__mobile-img'}
          style={{
            backgroundImage: `url(${publisherMetaData.storeTheme.general.backgroundImageMobile})`,
            position: 'fixed',
            zIndex: '-1',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        ></div>
      )}
      <HeaderWrapper
        showHeader={API.getOffers.data !== undefined}
        balanceQuantityFormat={publisherMetaData.storeTheme.general.balanceQuantityFormat}
        playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerName}
        openLogoutModal={openLogoutModal}
        logoSize={publisherMetaData.storeTheme.general.logoSize}
        balances={getBalancesData()}
        fontFamily={publisherMetaData.storeTheme.general.font}
        profileFrameImg={API.getOffers?.data?.profileFrameImg || ''}
        playerLevelImg={API.getOffers?.data?.playerLevelImg || ''}
      />
      {(processing || !allImagesLoaded) && (
        <Overlay overlayPercentage={80} zIndex={10000}>
          <AcCircularLoader text={t('processing')} />
        </Overlay>
      )}
      {!showPaymentModal && (
        <PopUpsHandler setProcessing={setProcessing} onPurchaseClick={selectOffer}></PopUpsHandler>
      )}
      <div className="offers-wrapper">
        {isProductMadness && (
          <ProductMadnessValueBlock
            mobileLogoImage={publisherMetaData.storeTheme.general.logo}
            desktopLogoImage={productMadnessDesktopLogo}
            backgroundImage={productMadnessValueBlock}
            playerLevelImage={API.getOffers?.data?.playerLevelImg}
            storeSaleImage={publisherMetaData.storeTheme.general?.storeSaleImage}
          ></ProductMadnessValueBlock>
        )}
        {bannerImage && (
          <Banner
            imageSrc={bannerImage}
            maxWidth={getProperty(EConfigs.bannerMaxWidth, bundlesInternalViewModel)}
            minWidth={getProperty(EConfigs.bannerMinWidth, bundlesInternalViewModel)}
          />
        )}
        {specialOffersList.length > 0 &&
          (specialOffersInternalViewModel === ESpecialOfferInternalViewModel.PLAY_SIMPLE ? (
            <div className="offers-wrapper--play-simple">
              {specialOffersList.map((offer: OfferData, i: number) => (
                <SpecialOffer data={offer} selectOffer={() => selectOffer(offer)} key={i} />
              ))}
            </div>
          ) : (
            <Carousel
              showDivider={isDividerDisplay}
              bundleViewModel={specialOffersInternalViewModel}
            >
              {specialOffersList.slice(0, CAROUSEL_LIMIT).map((offer: OfferData, i: number) => (
                <CarouselItem key={i} data={offer} index={i}>
                  <SpecialOffer data={offer} selectOffer={() => selectOffer(offer)} />
                </CarouselItem>
              ))}
            </Carousel>
          ))}
        {isNoSpecialOffers && (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            className="no_offers_box"
            padding={'2vh'}
          >
            <MessageBox
              title={
                publisherMetaData.storeTheme.storeScreen.noOffersTitleText || t('shop.noOffers')
              }
              text={
                publisherMetaData.storeTheme.storeScreen.noOffersMessageText ||
                t('shop.noSpecialOffers')
              }
              fontFamily={publisherMetaData.storeTheme.general.font}
              borderColor={publisherMetaData.storeTheme.storeScreen.bundleBorderColor.colorOne}
            />
          </Stack>
        )}
        <BundlesList selectOffer={selectOffer} />

        {bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE &&
          API.getOffers?.data?.playerLevelImg && (
            <div className="player-level-image">
              <img src={publisherMetaData.storeTheme.general.logo} alt="logo" />
              <img alt="Player Level" src={API.getOffers?.data?.playerLevelImg} />
            </div>
          )}
      </div>
      <Footer showFooter={API.getOffers.data !== undefined} isScriptLoaded={isScriptLoaded} />
      {!useNewCheckout && showPaymentModal && selectedOffer !== undefined && (
        <PaymentModal
          visible={true}
          fontFamily={publisherMetaData.storeTheme.general.font}
          headerText={
            publisherMetaData.storeTheme.paymentScreen.headerText || t('shop.paymentOptions')
          }
          headerColor={publisherMetaData.storeTheme.paymentScreen.headerColor || 'black'}
          headerSize={publisherMetaData.storeTheme.paymentScreen.headerSize}
          text={publisherMetaData.storeTheme.paymentScreen.text || t('shop.howWouldYouLikeToPay')}
          textColor={publisherMetaData.storeTheme.paymentScreen.textColor}
          textSize={publisherMetaData.storeTheme.paymentScreen.textSize}
          textWeight={publisherMetaData.storeTheme.paymentScreen.textWeight}
          modalColor={publisherMetaData.storeTheme.paymentScreen.popupColor}
          modalBorderColor={publisherMetaData.storeTheme.paymentScreen.popupBorderColor}
          paymentClicked={paymentClicked}
          closeModal={closePopup}
          buttonColor={
            publisherMetaData.storeTheme.general.buttonColor || {
              colorOne: 'green',
            }
          }
          buttonTextColor={publisherMetaData.storeTheme.general.buttonTextColor}
          preview={false}
          paymentMethods={API.getPostLoginData.data?.paymentMethods || paymentMethodsDefault}
          paypalButton={
            <PaypalButton
              clientToken={API.getPaypalKey.data}
              selectedOffer={selectedOffer}
              utmSource={utmSource}
              startLoader={() => setProcessing(true)}
            />
          }
        />
      )}
      {showLogoutModal && (
        <LogoutModal
          playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerName}
          closePopup={closePopup}
          showLogoutModal={showLogoutModal}
          logout={logout}
        />
      )}
      {showFailCollect && (
        <Overlay overlayPercentage={80}>
          {iframeLoading && <AcCircularLoader text={t('processing')} />}
          <iframe
            title={'store resolve'}
            className="resolve-iframe"
            src={resultIframeSrc}
            style={{
              visibility: iframeLoading ? 'hidden' : 'visible',
              transition: 'visibility 1s easy-in-out',
            }}
          ></iframe>
        </Overlay>
      )}
      {useNewCheckout && selectedOffer && showPaymentModal && (
        <Checkout
          offerId={selectedOffer.offerId}
          close={() => closePopup()}
          setProcessing={setProcessing}
          collectId={orderProcessing}
          currencyCode={selectedOffer.productsSequence[0].price.currencyCode}
        />
      )}
    </div>
  );
};

export default ShopPage;
