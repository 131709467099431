import React, { createContext, useEffect, useRef } from 'react';

import useApi from '../../hooks/useApi';
import { formatOfferDataToPublisherWebhook, getAppVersion } from '../../utils';
import { EPublisherWebhookEventType, EPublisherWebhookOfferEventType } from '../../constants/enums';
import { OfferEventData } from '../../constants/apiRequest.types';
import { OfferData } from '../../constants/apiResponses.types';

interface EventContextType {
  sendEvent: (event: OfferData) => void;
}

export const EventBatchContext = createContext<EventContextType | undefined>(undefined);

export const EventBatchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const eventsRef = useRef<OfferData[]>([]);
  const API = useApi({});
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const debounceDelay = 1000;

  const sendEvent = (event: OfferData) => {
    eventsRef.current.push(event);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      if (eventsRef.current.length > 0) {
        API.sendPublisherWebhookEvent.mutate({
          eventType: EPublisherWebhookEventType.OFFER,
          data: {
            eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
            offers: formatOfferDataToPublisherWebhook(eventsRef.current),
            appVersion: getAppVersion(),
          } as OfferEventData,
        });
        eventsRef.current = [];
      }
    }, debounceDelay);
  };

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return <EventBatchContext.Provider value={{ sendEvent }}>{children}</EventBatchContext.Provider>;
};
